import { Product } from '@commerce/types'
import { ProductMetafields } from '@commerce/types/product'

export const slugify = (text: string) =>
  text
    .toLocaleLowerCase()
    .trim()
    .replace(/[^\w\- ]+/g, '')
    .replace(/ +/g, '-')
    .replace(/\-\-+/g, '-')

export const findMetafieldValueByKey = (
  metafields: ProductMetafields[],
  key: string
) => {
  const result = metafields?.filter((m: any) => m.key === key)
  return result?.[0]?.value === '#N/A' ? null : result?.[0]?.value
}
export const orderMetafieldByKey = (metafields: ProductMetafields[]) => {
  let metafieldsWithOrder: ProductMetafields[] = []
  metafieldsWithOrder[0] = metafields?.filter(
    (m: ProductMetafields) => m.key === COLOUR_CARD_METAFIELD_KEY
  )[0]
  metafieldsWithOrder[1] = metafields?.filter(
    (m: ProductMetafields) => m.key === SAMPLE_POT_METAFIELD_KEY
  )[0]
  metafieldsWithOrder[2] = metafields?.filter(
    (m: ProductMetafields) => m.key === GARDEN_POT_PAINT_METAFIELD_KEY
  )[0]
  metafieldsWithOrder[3] = metafields?.filter(
    (m: ProductMetafields) => m.key === LIMEWASH_PAINT_METAFIELD_KEY
  )[0]
  metafieldsWithOrder[4] = metafields?.filter(
    (m: ProductMetafields) => m.key === PAINT_15_LITRE_METAFIELD_KEY
  )[0]
  return metafieldsWithOrder?.filter((m) => m)
}

export type BundleInfo = {
  variant: string
  name: string
  description: string
  [key: string]: string
}

export const findBundleInfoFromMetafield = (
  metafields: ProductMetafields[] | undefined
): null | BundleInfo[] => {
  if (!metafields) return null
  const smallBundle: BundleInfo = {
    variant: '',
    name: '',
    description: '',
  }
  const largeBundle: BundleInfo = {
    variant: '',
    name: '',
    description: '',
  }

  metafields.forEach((item) => {
    if (item.key.startsWith('small_set')) {
      const key = item.key.replace('small_set_', '')
      smallBundle[key] = item.value
    } else if (item.key.startsWith('large_set')) {
      const key = item.key.replace('large_set_', '')
      largeBundle[key] = item.value
    }
  })
  if (
    Object.values(smallBundle).every((value) => value === '') &&
    Object.values(largeBundle).every((value) => value === '')
  ) {
    return null
  }
  const bundleInfo: BundleInfo[] = [smallBundle, largeBundle]
  return bundleInfo
}

export const findcustomAttributeByKey = (
  customAttributes: any[],
  key: string
) => {
  const result = customAttributes.filter((c: any) => c.key === key)
  return result?.[0]?.value || null
}
export const PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY = '_parentProduct'
export const PRODUCT_TYPE_CUSTOM_ATTRIBUTE_KEY = '_productType'
export const COLOUR_CODE_CUSTOM_ATTRIBUTE_KEY = '_colourCode'
export const RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY = 'Retail Price'
export const PRODUCT_CUSTOM_ATTRIBUTES_KEYS = {
  PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY,
  PRODUCT_TYPE_CUSTOM_ATTRIBUTE_KEY,
  COLOUR_CODE_CUSTOM_ATTRIBUTE_KEY,
  RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY,
}
export const COLOUR_CARD_METAFIELD_KEY = 'colour_card'
export const SAMPLE_POT_METAFIELD_KEY = 'sample_pot'
export const GARDEN_POT_PAINT_METAFIELD_KEY = 'garden_pot_paint'
export const LIMEWASH_PAINT_METAFIELD_KEY = 'limewash_paint'
export const PAINT_15_LITRE_METAFIELD_KEY = 'paint_15_litre'

export const COLOUR_CARD_OPTION_LABEL = 'Colour Card'
export const SAMPLE_POT_OPTION_LABEL = 'Sample Pot'
export const GARDEN_POT_PAINT_OPTION_LABEL = 'Garden Pot Paint'
export const PAINT_OPTION_LABEL = 'Paint'
export const PAINT_15_LITRE_OPTION_LABEL = 'Paint 15 Litre'

export const PRODUCT_SIBLINGS_METAFIELD_KEYS = [
  COLOUR_CARD_METAFIELD_KEY,
  SAMPLE_POT_METAFIELD_KEY,
  GARDEN_POT_PAINT_METAFIELD_KEY,
  LIMEWASH_PAINT_METAFIELD_KEY,
  PAINT_15_LITRE_METAFIELD_KEY,
]

export const PRODUCT_TYPE_COLOUR = 'Colour'
export const PRODUCT_TYPE_TOOLS = 'Tools and Supplies'
export const PRODUCT_TYPE_BUNDLE = 'Set'
export const PRODUCT_TYPE_LANDING_PAGE = 'Landing Page'
export const PRODUCT_TYPE_COLOUR_CONSULTATION = 'Colour Consultation'
export const HIDDEN_TAG = 'search:hidden'
export const B2B_TAG = 'b2b'
export const COLOUR_PRODUCT_SIMILAR_COLOURS = 'similar_colours'
export const PRODUCT_SIMILAR_PRODUCTS = 'similar_products'
export const PRODUCT_SHORT_DESCRIPTION = 'short_description'
export const HIDE_SAUCE_TAG = 'hidesauce'

export const PRODUCT_TYPE_COLOUR_CARD = 'Colour Card'
export const PRODUCT_TYPE_SAMPLE_POT = 'Sample Pot'
export const PRODUCT_TYPE_GARDEN_POT_PAINT = 'Garden Pot Paint'
export const PRODUCT_TYPE_PAINT = 'Paint'

export const GENERAL_PAGE_SIDE_NAVIGATION_HANDLE =
  'general-page-side-navigation'
export const AUX_PAGE_NAVIGATION_HANDLE = 'aux-page-navigation'
export const STOCKISTS_PAGE_NAVIGATION_HANDLE = 'stockists-page-navigation'
export const ARTICLE_LISTING_PAGE_NAVIGATION_HANDLE =
  'article-listing-page-navigation'

export const childProductTypes = [
  PRODUCT_TYPE_COLOUR_CARD,
  PRODUCT_TYPE_SAMPLE_POT,
  PRODUCT_TYPE_GARDEN_POT_PAINT,
  PRODUCT_TYPE_PAINT,
]

// function to get parent product from child product
export const getParentProductHandleFromChildProduct = (
  product: Product.Product
): false | string => {
  const isChildProduct = childProductTypes.includes(product?.productType!)
  if (!isChildProduct) return false

  return product.handle!.replace(
    `-${slugify(product.productType!)}`,
    '-limewash-paint-colour'
  )
  // switch (product.productType) {
  //   case PRODUCT_TYPE_COLOUR_CARD: {
  //     return product.handle!.replace(`_${slugify(PRODUCT_TYPE_COLOUR_CARD)}`, '')
  //   }
  //   case PRODUCT_TYPE_SAMPLE_POT: {
  //     return product.handle!.replace(`_${slugify(product.handle!)}`, '')
  //   }
  //   case PRODUCT_TYPE_GARDEN_POT_PAINT: {
  //     return product.handle!.replace(`_${slugify(product.handle!)}`, '')
  //   }
  //   case PRODUCT_TYPE_PAINT: {
  //     return product.handle!.replace(`_${slugify(product.handle!)}`, '')
  //   }
  // }
}
